/**
 * Dynamic List,with view details button  .
 * Utilizing React Redux for state management.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import { Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import TransactionListObj from './transactionListObj';
import Loader from '../common/loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTransaction,
  getTransactionByChainOrWalletOrHash,
  searchByChain
} from '../../redux/features/transactionSlice ';
import { useNavigate } from 'react-router-dom';
import { config } from '../../config';

const TransactionList = ({ selectedChain, isFilter, search }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const transaction = useSelector((state) => state.transaction);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageOffset, setPageOffset] = useState(-1);

  useEffect(() => {
    if (isFilter === false || (isFilter === true && selectedChain === '' && search === '')) {
      // empty or filter is not applied
      dispatch(
        getTransaction({
          headers: {
            headers: {
              authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
            }
          },
          currentPage: currentPage,
          dispatch: dispatch,
          navigate: navigate
        })
      );
    } else if (search === '') {
      // only chain
      dispatch(
        getTransactionByChainOrWalletOrHash({
          headers: {
            headers: {
              authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
            }
          },
          selectedChain: selectedChain,
          currentPage: currentPage,
          dispatch: dispatch,
          navigate: navigate
        })
      );
    } else if (selectedChain === '') {
      // By search wallet ot trnx hash
      dispatch(
        getTransactionByChainOrWalletOrHash({
          headers: {
            headers: {
              authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
            }
          },
          userWalletAddress: search,
          transactionHash: search,
          currentPage: currentPage,
          dispatch: dispatch,
          navigate: navigate
        })
      );
    } else {
      // search with chain
      dispatch(
        searchByChain({
          headers: {
            headers: {
              authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
            }
          },
          selectedChain: selectedChain,
          userWalletAddress: search,
          transactionHash: search,
          currentPage: currentPage,
          dispatch: dispatch,
          navigate: navigate
        })
      );
    }
  }, [currentPage, isFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const HandlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setPageOffset(event.selected);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [isFilter]);
  return (
    <>
      {transaction.isLoading ? (
        <Loader />
      ) : (
        <>
          <Table className="recordes-table mb-0" responsive striped>
            <thead>
              <tr>
                <th>TransactionHash</th>
                <th>UserWallet</th>
                <th>ChainID</th>
                <th>ChainName</th>
                <th colSpan={2}>Fee</th>
              </tr>
            </thead>
            <tbody>
              {transaction.transactionData?.map((trnx) => {
                return (
                  <tr key={trnx._id}>
                    <TransactionListObj trnx={trnx}></TransactionListObj>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              onPageChange={HandlePageClick}
              pageRangeDisplayed={5}
              pageCount={transaction.pageCount}
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              marginPagesDisplayed={2}
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
        </>
      )}
    </>
  );
};
export default TransactionList;
