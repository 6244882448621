/**
 * Managing user.
 * Features:
 *        -> List of users
 *        -> Add user
 *        -> Edit/Delete users
 * It utilizes Redux state for tracking user errors and update
 * Author: Nauman Sukhera
 * Date: 15 Nov, 2023
 */
import React, { useState, useEffect } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Banner from '../common/banner';
import UserList from './usersList';
import Sidebar from '../common/sidebar';
import UserPofile from '../common/userPofile';
import CopyRights from '../common/copyRights';
import { AddUser } from './addUser';
import { Toast } from '../../config';
import { toastHandler } from '../../redux/features/adminSlice';

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);

  const [isUpdated, setIsUpdated] = useState(false);
  const admin = useSelector((state) => state.admin);
  useEffect(() => {
    if (admin.isError === true) {
      Toast.fire({
        icon: 'error',
        title: admin.error
      });
      dispatch(toastHandler());
    } else if (admin.userAdded === true || admin.isUpdated === true) {
      dispatch(toastHandler());

      Toast.fire({
        icon: 'success',
        title: 'Success'
      });
      setIsUpdated(!isUpdated);
      setShowAddUserModal(false);
      setShowEditUserModal(false);
    }
  }, [admin]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="main-wrapper dashboard d-flex">
        <Sidebar />
        <div className="content-container">
          <UserPofile />
          <Banner
            heading={'Admin users'}
            subheading={'Manage all admin users'}
            text={
              "Take control of your application's administration with the comprehensive 'Manage All Admin Users' section. This central hub empowers you with the tools to oversee and maintain the integrity of your administrative team."
            }
          />

          <>
            <Stack direction="horizontal" gap={3} className="justify-content-end flex-wrap mb-4">
              <Button
                className="gradient-btn btn-lg"
                onClick={() => {
                  navigate('/roles');
                }}
              >
                Manage roles
              </Button>
              <Button
                className="gradient-btn btn-lg"
                onClick={() => {
                  setShowAddUserModal(true);
                }}
              >
                Add new user
              </Button>
            </Stack>
            <AddUser modalShow={showAddUserModal} setModalShow={setShowAddUserModal}></AddUser>
          </>

          <UserList
            setIsUpdated={setIsUpdated}
            isUpdated={isUpdated}
            showEditUserModal={showEditUserModal}
            setShowEditUserModal={setShowEditUserModal}
          />
        </div>
        <></>
      </div>
      <CopyRights />
    </>
  );
};

export default Users;
