/** Transaction detail page.
 * Features:
 *        -> List of burn transaction
 *        -> shows parent transaction
 * Find from available redux transaction state, if not found then dispatch action
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import Banner from '../common/banner';
import ParentTransaction from './parentTransaction';
import BurnList from './burnList';
import Sidebar from '../common/sidebar';
import UserPofile from '../common/userPofile';
import CopyRights from '../common/copyRights';
import { getTransactionByChainOrWalletOrHash } from '../../redux/features/transactionSlice ';
import { config } from '../../config';

const BurnTransactions = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const transaction = useSelector((state) => state.transaction);
  const [targetTransaction, setTargetTransaction] = useState();
  const [search, setSearch] = useState(false);

  const fetchTransactionData = async () => {
    const tempTransaction = await transaction.transactionData.find(
      // find from current state
      (transaction) => transaction.transactionHash === params.transactionHash
    );
    setTargetTransaction(tempTransaction);
    if (!tempTransaction && search === false) {
      dispatch(
        getTransactionByChainOrWalletOrHash({
          // dispatch action if not found
          headers: {
            headers: {
              authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
            }
          },
          transactionHash: params.transactionHash,
          currentPage: 1,
          dispatch: dispatch,
          navigate: navigate
        })
      );
      setSearch(true);
    }
    // set the targetTransaction
  };

  useEffect(() => {
    fetchTransactionData();
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (search === true) {
      setTargetTransaction(transaction.transactionData[0]);
    }
  }, [search, transaction]);

  return (
    <>
      <div className="main-wrapper dashboard d-flex">
        <Sidebar />
        <div className="content-container">
          <UserPofile />
          <Banner
            heading={'Transactions'}
            subheading={'View all burn transactions'}
            text={
              'This specialized section offers a comprehensive and chronological record of all token burn events that have occurred within our platform.'
            }
          />
          <Button
            variant="none border border-1 fw-medium mb-3"
            onClick={() => {
              navigate('/transactions');
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <ParentTransaction targetTransaction={targetTransaction} />
          <BurnList targetTransaction={targetTransaction} />
        </div>
        <></>
      </div>
      <CopyRights />
    </>
  );
};

export default BurnTransactions;
