/**
 * Page for managing burn fees.
 * Features a fee management form.
 * Additionally, the component utilizes Redux for state management, displaying toast notifications for success or error messages related to fee updates.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FeeForm from './feeForm';
import Sidebar from '../common/sidebar';
import Banner from '../common/banner';
import UserPofile from '../common/userPofile';
import { Toast } from '../../config';
import { toastHandler } from '../../redux/features/feeSlice';

const Index = () => {
  const dispatch = useDispatch();
  const fee = useSelector((state) => state.fee);
  useEffect(() => {
    // Track fee state for error & updates toasts
    if (fee.isError === true) {
      Toast.fire({
        icon: 'error',
        title: fee.error
      });
      dispatch(toastHandler());
    } else if (fee.isUpdated === true) {
      dispatch(toastHandler());

      Toast.fire({
        icon: 'success',
        title: 'Success'
      });
    }
  }, [fee]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="main-wrapper d-flex">
      <Sidebar />
      <div className="content-container">
        <UserPofile />
        <Banner
          heading={'Fee Management'}
          subheading={'Manage your burn fee'}
          text={
            'To manage the power to regulate the burn rate applied to transactions within our platform'
          }
        />
        <FeeForm />
      </div>
    </div>
  );
};

export default Index;
