/**
 * Display refund list item according to props passed ,with appropriate actions .
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Button, Overlay, Tooltip } from 'react-bootstrap';
import { useState, useRef } from 'react';

import { handleCopy } from '../../utlis/handleCopy';

const RefundListObj = ({ refund, handleUpdateRefund }) => {
  let actionButtons;
  // change color
  const [copyClassHash, setCopyClassHash] = useState('copy');
  const [copyClassWallet, setCopyClassWallet] = useState('copy');
  // show tooltop
  const [show, setShow] = useState(false);
  const [showWalletTooltip, setWalletTooltip] = useState(false);

  const targetWallet = useRef(null);
  const targetHash = useRef(null);

  switch (refund?.status) {
    case 'pending':
      actionButtons = (
        <>
          <Button
            className="rounded-0 d-flex align-items-center justify-content-center w-100"
            variant="danger"
            onClick={() => {
              handleUpdateRefund('rejected', refund._id, refund.status);
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="me-2" />
            Reject
          </Button>

          <Button
            className="rounded-0 d-flex align-items-center justify-content-center w-100"
            variant="success"
            onClick={() => {
              handleUpdateRefund('approved', refund._id, refund.status);
            }}
          >
            <FontAwesomeIcon icon={faCheck} className="me-2" />
            Approve
          </Button>
        </>
      );
      break;

    case 'approved':
      actionButtons = (
        <Button
          className="rounded-0 d-flex align-items-center justify-content-center w-100"
          variant="danger"
          onClick={() => {
            handleUpdateRefund('rejected', refund._id, refund.status);
          }}
        >
          <FontAwesomeIcon icon={faXmark} className="me-2" />
          Reject
        </Button>
      );
      break;

    case 'rejected':
      actionButtons = (
        <Button
          className="rounded-0 d-flex align-items-center justify-content-center w-100"
          variant="success"
          onClick={() => {
            handleUpdateRefund('approved', refund._id, refund.status);
          }}
        >
          <FontAwesomeIcon icon={faCheck} className="me-2" />
          Approve
        </Button>
      );
      break;

    default:
      actionButtons = null;
  }

  return (
    <>
      <td>
        <Link to={`/transaction/details/${refund?.feeTransaction[0]?.transactionHash}`}>
          {refund?.feeTransaction[0]?.transactionHash}
        </Link>
        <FontAwesomeIcon
          className={`ms-3 ${copyClassHash}`}
          icon={faCopy}
          role="button"
          ref={targetHash}
          onClick={() => {
            handleCopy(setCopyClassHash, setShow, show, refund?.feeTransaction[0]?.transactionHash);
          }}
        />
        <Overlay target={targetHash.current} show={show} placement="top">
          {(props) => <Tooltip {...props}>Copied</Tooltip>}
        </Overlay>
      </td>
      <td>
        {refund?.feeTransaction[0]?.userWalletAddress}{' '}
        <FontAwesomeIcon
          className={`ms-3 ${copyClassWallet}`}
          icon={faCopy}
          role="button"
          ref={targetWallet}
          onClick={() => {
            handleCopy(
              setCopyClassWallet,
              setWalletTooltip,
              showWalletTooltip,
              refund?.feeTransaction[0]?.userWalletAddress
            );
          }}
        />
        <Overlay target={targetWallet.current} show={showWalletTooltip} placement="top">
          {(props) => <Tooltip {...props}>Copied</Tooltip>}
        </Overlay>
      </td>
      <td>{refund?.feeTransaction[0]?.chainName || refund?.feeTransaction[0]?.chainID}</td>
      <td className="word-break-none">
        <div className={`status ${refund.status} fw-medium`}>
          {refund?.status
            ? `${refund.status.charAt(0).toUpperCase()}${refund.status.slice(1)}`
            : ''}
        </div>
        <br />
        {new Date(refund?.updatedAt).toLocaleString()}
      </td>
      <td align="right">
        <div className="d-flex flex-xl-nowrap flex-wrap">{actionButtons}</div>
      </td>
    </>
  );
};
export default RefundListObj;
