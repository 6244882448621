/**
 * List item render,diplay data according to props passed.
 * Button to view details
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import { Button } from 'react-bootstrap';

const ContactRequestListObj = ({ contactObj, setModalShow, setCurrentDetailContact }) => {
  return (
    <>
      <td>{contactObj?.name}</td>
      <td>{contactObj?.email}</td>
      <td>{contactObj?.status === 1 ? 'Pending' : 'Opened'}</td>
      <td>
        <Button
          className="btn-sm-blue"
          onClick={() => {
            setCurrentDetailContact(contactObj);
            setModalShow(true);
          }}
        >
          View
        </Button>
      </td>
    </>
  );
};
export default ContactRequestListObj;
