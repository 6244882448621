/**
 * Display user list item according to props passed with Edit & Delete actions.
 * Edit user trigers popup in editUser component.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import Swal from 'sweetalert2';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import { deleteUser } from '../../redux/features/adminSlice';
import { useDispatch } from 'react-redux';
import { config } from '../../config';

const UsersListObj = ({ user, setCurrentEditUser, setModalShow }) => {
  const dispatch = useDispatch();
  const handleDeleteUser = () => {
    Swal.fire({
      title: `Do you want to delete the user ${user.fullName} ?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      denyButtonText: `Cancel`
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteUser({
            headers: {
              authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
            },
            payload: user._id
          })
        );
      }
    });
  };
  return (
    <>
      <td>{user.fullName}</td>
      <td>📧 {user.email}</td>
      <td>{user.role.title}</td>
      <td>{user.status === true ? 'Active' : 'Inactive'}</td>
      <td>
        <div className="d-inline-flex gap-2">
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit User</Tooltip>}>
            <Button
              className="btn-sm-blue"
              onClick={() => {
                setCurrentEditUser({
                  user_id: user._id,
                  name: user.fullName,
                  email: user.email,
                  role: user.role,
                  status: user.status
                });
                setModalShow(true);
              }}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={<Tooltip id="tooltip-top">Delete User</Tooltip>}
          >
            <Button className="btn-danger" onClick={handleDeleteUser}>
              <FontAwesomeIcon icon={faTrashCan} />
            </Button>
          </OverlayTrigger>
        </div>
      </td>
    </>
  );
};
export default UsersListObj;
