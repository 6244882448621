/**
 * Refund page for managing refund requests.
 * Features a list of refund requests.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React from 'react';

import Banner from '../common/banner';
import RefundList from './refundList';
import Sidebar from '../common/sidebar';
import UserPofile from '../common/userPofile';
import CopyRights from '../common/copyRights';

const Refund = () => {
  return (
    <>
      <div className="main-wrapper dashboard d-flex">
        <Sidebar />
        <div className="content-container">
          <UserPofile />
          <Banner
            heading={'Refund Requests'}
            subheading={'Manage all refund requests'}
            text={
              'Access all incoming refund requests, complete with status indicators, and  customer information.'
            }
          />
          <RefundList />
        </div>
        <></>
      </div>
      <CopyRights />
    </>
  );
};

export default Refund;
