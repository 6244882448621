/**
 * Page for managing contact requests. It includes a sidebar, user profile section, and a list of contact requests.
 * The main content area displays a banner with information about viewing and responding to contact requests.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React, { useState } from 'react';

import Banner from '../common/banner';
import ContactRequestList from './contactRequestList';
import Sidebar from '../common/sidebar';
import UserPofile from '../common/userPofile';
import CopyRights from '../common/copyRights';

const ContactRequest = () => {
  const [isUpdated, setIsUpdated] = useState(false); //to keep track of updates

  return (
    <>
      <div className="main-wrapper dashboard d-flex">
        <Sidebar />
        <div className="content-container">
          <UserPofile />
          <Banner
            heading={'Contact Requests'}
            subheading={'View all contact requests'}
            text={
              'Section where you can review and respond to inquiries submitted by users, customers, or potential clients'
            }
          />
          <ContactRequestList setIsUpdated={setIsUpdated} isUpdated={isUpdated} />
        </div>
      </div>
      <CopyRights />
    </>
  );
};

export default ContactRequest;
