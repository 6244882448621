/**
 * Role page for managing user roles and permsissions.
 * Features:
 *        -> List of Roles
 *        -> Add Role
 * It utilizes Redux state for tracking role errors and update
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import React, { useState, useEffect } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Banner from '../common/banner';
import RolesList from './rolesList';
import Sidebar from '../common/sidebar';
import UserPofile from '../common/userPofile';
import CopyRights from '../common/copyRights';
import { AddRole } from './addRole';
import { Toast } from '../../config';
import { toastHandler } from '../../redux/features/rolesSlice';

const Roles = () => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.role);

  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [showEditRoleModal, setShowEditRoleModal] = useState(false);

  useEffect(() => {
    if (role.isError === true) {
      Toast.fire({
        icon: 'error',
        title: role.error
      });
      dispatch(toastHandler());
    } else if (role.isUpdated === true) {
      dispatch(toastHandler());
      Toast.fire({
        icon: 'success',
        title: 'Success'
      });
      setShowAddRoleModal(false);
      setIsUpdated(!isUpdated);
      setShowEditRoleModal(false);
    }
  }, [role]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="main-wrapper dashboard d-flex">
        <Sidebar />
        <div className="content-container">
          <UserPofile />
          <Banner
            heading={'Roles'}
            subheading={'Manage admin roles'}
            text={'Where you can create or manage the admin roles and access to the panel. '}
          />

          <Stack direction="horizontal" gap={3} className="justify-content-end mb-4">
            <Button
              className="gradient-btn btn-lg"
              onClick={() => {
                setShowAddRoleModal(true);
              }}
            >
              Add Role
            </Button>
          </Stack>
          <AddRole modalShow={showAddRoleModal} setModalShow={setShowAddRoleModal}></AddRole>

          <RolesList
            setIsUpdated={setIsUpdated}
            isUpdated={isUpdated}
            showEditRoleModal={showEditRoleModal}
            setShowEditRoleModal={setShowEditRoleModal}
          />
        </div>
      </div>
      <CopyRights />
    </>
  );
};

export default Roles;
