/**
 * Display Detailed view of contact request.
 * Change the status of the contact request.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import { Modal, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { editContact } from '../../redux/features/constactSlice';
import ButtonMailTo from '../../utlis/buttonMailTo';
import { config } from '../../config';

export const DetailContactRequest = ({
  modalShow,
  currentDetailContact,
  setModalShow,
  setIsUpdated
}) => {
  const dispatch = useDispatch();
  const [isUpdated, setISUpdated] = useState(false);

  useEffect(() => {
    if (currentDetailContact?.status === 1 && isUpdated === false && modalShow === true) {
      dispatch(
        editContact({
          headers: {
            headers: {
              authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
            }
          },
          payload: { _id: currentDetailContact?._id, status: 0 } // once opened status is changed to viewed from pending
        })
      );
      setISUpdated(true);
    }
  }, [modalShow]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal
        size="lg"
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setIsUpdated(!isUpdated);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3 className="text-black fw-medium"> Contact Request</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-md-5 py-4">
          <Form className="px-lg-5">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" defaultValue={currentDetailContact?.email} disabled />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                disabled
                placeholder="Name"
                defaultValue={currentDetailContact?.name}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                disabled
                defaultValue={currentDetailContact?.message}
              />
            </Form.Group>
            <ButtonMailTo
              email={currentDetailContact?.email}
              subject={'BurnWallet Reply'}
              label="Reply"
            />
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
