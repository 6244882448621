/**
 * Dynamic role list,with appropriate actions.
 * Utilizing React Redux for state management.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import { Col, Container, Row, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import RolesListObj from './rolesListObj';
import { EditRole } from './editRole';
import Loader from '../common/loader';
import { getRoles } from '../../redux/features/rolesSlice';
import { config } from '../../config';
const RolesList = ({ isUpdated, setShowEditRoleModal, setIsUpdated, showEditRoleModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = useSelector((state) => state.role);
  const [currentEditRole, setCurrentEditRole] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageOffset, setPageOffset] = useState(-1);

  useEffect(() => {
    dispatch(
      getRoles({
        headers: {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
          }
        },
        currentPage: currentPage,
        dispatch: dispatch,
        navigate: navigate
      })
    );
  }, [currentPage, isUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const HandlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    setPageOffset(event.selected);
  };

  return (
    <>
      {role.isLoading ? (
        <Loader />
      ) : (
        <>
          <Container fluid>
            <Row>
              <Col lg={6}>
                <Table className="recordes-table users-table mb-0" responsive striped>
                  <thead>
                    <tr>
                      <th key={'title'}>Title</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {role.roleData?.map((role) => {
                      return (
                        <tr key={role._id}>
                          <RolesListObj
                            role={role}
                            setCurrentEditRole={setCurrentEditRole}
                            setModalShow={setShowEditRoleModal}
                          ></RolesListObj>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              onPageChange={HandlePageClick}
              pageRangeDisplayed={5}
              pageCount={role.pageCount}
              renderOnZeroPageCount={null}
              containerClassName="pagination col-6"
              marginPagesDisplayed={2}
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
          <EditRole
            modalShow={showEditRoleModal}
            setModalShow={setShowEditRoleModal}
            currentEditRole={currentEditRole}
          />
        </>
      )}
    </>
  );
};
export default RolesList;
