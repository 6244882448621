/**
 * Dynamic List of user   .
 * Utilizing React Redux for state management.
 * Author: Nauman Sukhera
 * Date: 15 Nov, 2023
 */
import { Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ReactPaginate from 'react-paginate';
import UsersListObj from './usersListObj';
import { EditUser } from './editUser';
import Loader from '../common/loader';
import { getUsers } from '../../redux/features/adminSlice';
import { config } from '../../config';

const UsersList = ({ isUpdated, showEditUserModal, setShowEditUserModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admin = useSelector((state) => state.admin);

  const [currentEditUser, setCurrentEditUser] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageOffset, setPageOffset] = useState(-1);

  useEffect(() => {
    dispatch(
      //dispatch to get users via redux
      getUsers({
        headers: {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
          }
        },
        currentPage: currentPage,
        dispatch: dispatch,
        navigate: navigate
      })
    );
  }, [currentPage, isUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const HandlePageClick = (event) => {
    //handle pagination
    setCurrentPage(event.selected + 1);
    setPageOffset(event.selected);
  };

  return (
    <>
      {admin.isLoading ? (
        <Loader />
      ) : (
        <>
          <Table className="recordes-table users-table mb-0" responsive striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th colSpan={2}>Status</th>
              </tr>
            </thead>
            <tbody>
              {admin.userList?.map((user) => {
                return (
                  <tr key={user._id}>
                    <UsersListObj
                      user={user}
                      setCurrentEditUser={setCurrentEditUser}
                      setModalShow={setShowEditUserModal}
                    ></UsersListObj>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              onPageChange={HandlePageClick}
              pageRangeDisplayed={5}
              pageCount={admin.pageCount}
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              marginPagesDisplayed={2}
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
          <EditUser
            modalShow={showEditUserModal}
            setModalShow={setShowEditUserModal}
            currentEditUser={currentEditUser}
          />
        </>
      )}
    </>
  );
};
export default UsersList;
