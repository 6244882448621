/**
 * Countries chart
 * ECharts library to dynamically display a bar chart of transaction statistics grouped by country.
 * It fetches data using Redux from the backend, processes and sorts the information, and then renders the top countries with their transaction counts.
 * The chart's appearance is customized using gradients, and the top country is highlighted with its flag.
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */

import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { config } from '../../config';
import { getTransactionStatsByCountry } from '../../redux/features/transactionSlice ';

const CountriesChart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transaction = useSelector((state) => state.transaction);

  const [topCountry, setTopCountry] = useState('US');
  const [chartData, setChartData] = useState({
    xAxis: {
      type: 'category',
      data: []
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [],
        type: 'bar',
        itemStyle: {
          borderRadius: [50, 50, 0, 0],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#0F3CB4' },
            { offset: 0.5, color: '#1E2184' },
            { offset: 1, color: '#1E1F6B' }
          ])
        },
        emphasis: {
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#1E1F6B' },
              { offset: 0.5, color: '#1E2184' },
              { offset: 1, color: '#0F3CB4' }
            ])
          }
        }
      }
    ]
  });

  useEffect(() => {
    dispatch(
      getTransactionStatsByCountry({
        headers: {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
          }
        },
        dispatch: dispatch,
        navigate: navigate
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (transaction.statsByCountry.length > 0) {
      const feeStats = transaction.statsByCountry;

      // Group the data by country and count the number of items (transactions) for each country
      const countryData = feeStats.reduce((acc, item) => {
        const key = item._id.country || 'Unknown';
        acc[key] = item.count || 0;
        return acc;
      }, {});

      // Convert the object into an array of objects for sorting
      const countryArray = Object.keys(countryData).map((country) => ({
        country,
        count: countryData[country]
      }));

      // Sort the array in descending order based on the count
      countryArray.sort((a, b) => b.count - a.count);

      // Extract the sorted keys (countries) and values (counts)
      const xAxisData = countryArray.map((item) => item.country);
      const seriesData = countryArray.map((item) => item.count);

      if (countryArray.length > 0) {
        setTopCountry(countryArray[0].country);
      }

      setChartData({
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        series: [
          {
            data: seriesData,
            type: 'bar',
            barMaxWidth: 50,
            itemStyle: {
              borderRadius: [50, 50, 0, 0],
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#0F3CB4' },
                { offset: 0.5, color: '#1E2184' },
                { offset: 1, color: '#1E1F6B' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#1E1F6B' },
                  { offset: 0.5, color: '#1E2184' },
                  { offset: 1, color: '#0F3CB4' }
                ])
              }
            }
          }
        ]
      });
    }
  }, [transaction]);
  return (
    <section className="countries-chart d-sm-flex">
      <div className="top-countries d-inline-flex align-items-sm-center position-relative">
        <h5 className="text-rotate opacity-100 fw-bold">Countries</h5>
        <div className="flag-wrapper d-flex flex-column align-items-center">
          <span className="top-visitors">Top Visitors</span>
          <ReactCountryFlag
            countryCode={topCountry}
            svg
            style={{
              width: '60px',
              height: '60px'
            }}
            title={topCountry}
          />

          {topCountry}
        </div>
      </div>
      <ReactEcharts option={chartData} style={{ width: '100%', height: '300px' }}></ReactEcharts>
    </section>
  );
};

export default CountriesChart;
