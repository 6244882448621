/**
 * Display a editable form of user
 * Author: Nauman Sukhera
 * Date: 14 Nov, 2023
 */
import { Button, Modal, Form, Stack } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getAllRoles } from '../../redux/features/rolesSlice';
import { editUser } from '../../redux/features/adminSlice';
import { config } from '../../config';

export const EditUser = ({ modalShow, setModalShow, currentEditUser }) => {
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState();
  const [isActive, setIsActive] = useState(true);
  const nameRef = useRef();
  const roleIdRef = useRef();
  const role = useSelector((state) => state.role);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  useEffect(() => {
    dispatch(
      getAllRoles({
        headers: {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
          }
        }
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEdit = (event) => {
    event.preventDefault();
    const payload = {
      _id: currentEditUser.user_id,
      fullName: nameRef.current.value,
      roleId: roleIdRef.current.value,
      status: isActive
    };
    dispatch(
      editUser({
        headers: {
          headers: {
            authorization: `Bearer ${localStorage.getItem(config.jwtToken)}`
          }
        },
        payload: payload
      })
    );
  };
  return (
    <>
      <Modal
        size="md"
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3 className="text-black fw-medium"> Edit User</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-md-5 py-4">
          <Form onSubmit={(event) => handleEdit(event)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" defaultValue={currentEditUser?.email} disabled />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                ref={nameRef}
                placeholder="Name"
                defaultValue={currentEditUser?.name}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Select
                onChange={handleRoleChange}
                value={selectedRole}
                ref={roleIdRef}
                defaultValue={currentEditUser?.role?._id}
              >
                {role.roleData?.map((roleObj) => (
                  <option key={roleObj._id} value={roleObj._id}>
                    {roleObj.title}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Check
              className="custom-switch d-inline-block align-items-center"
              type="switch"
              label="Active"
              checked={isActive}
              onChange={() => {
                setIsActive(!isActive);
              }}
              defaultChecked={currentEditUser?.status}
              reverse
            />

            <Stack direction="horizontal" gap={3} className="justify-content-center mt-4">
              <Button className="btn-cancel btn-lg" onClick={() => setModalShow(false)}>
                Cancel
              </Button>
              <Button className="btn-success btn-lg" type="submit">
                Submit
              </Button>
            </Stack>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
